<template>
  <div>
    <div v-if="inTabEdit">
      <div class="relative">
        <div class="flex flex-no-wrap w-full" vs-w="12">
          <div
            class="mr-2"
            v-if="check_has_permission('exportNurseManagement') && !franchiseId"
          >
            <vs-button
              class="vs-con-loading__container"
              id="button-with-loading"
              @click="exportCsvHandler"
              icon-pack="feather"
              icon="icon-edit"
              :disabled="users.length <= 0"
              >Export as CSV</vs-button
            >
            <a
              id="downloadCustomerCsv"
              ref="downloadCustomerCSVRef"
              :href="customerCSVurl"
            ></a>
          </div>
          <div class="lg:absolute lg:right-0">
            <div class="flex flex-no-wrap">
              <div
                class="mr-2"
                v-if="
                  $store.state.AppActiveUser.userType === 'superAdmin' ||
                  ($store.state.AppActiveUser.userType === 'admin' &&
                    canCreate &&
                    !franchiseId)
                "
              >
                <vs-button
                  @click="createUserHandler"
                  icon-pack="feather"
                  icon="icon-edit"
                >
                  Create Nurse
                </vs-button>
              </div>
              <!-- <div
                class="mr-2"
                v-if="
                  $store.state.AppActiveUser.userType === 'superAdmin' ||
                  ($store.state.AppActiveUser.userType === 'admin' &&
                    canCreate &&
                    !franchiseId)
                "
              >
                <vs-button
                  @click="onboardNurseHandler"
                  icon-pack="feather"
                  icon="icon-edit"
                >
                  Onboard Nurse
                </vs-button>
              </div>
              <div
                class="mr-2"
                v-if="
                  $store.state.AppActiveUser.userType === 'franchise' ||
                  $store.state.AppActiveUser.userType === 'subFranchise' ||
                  $store.state.AppActiveUser.userType === 'clinic' ||
                  $store.state.AppActiveUser.userType === 'subClinic'
                "
              >
                <invite-user
                  invitationType="Nurse"
                  initiator="management"
                  :clinicCount="clinicCount"
                  :invitedBy="
                    $store.state.AppActiveUser.userType === 'subFranchise'
                      ? 'franchise'
                      : $store.state.AppActiveUser.userType === 'subClinic'
                      ? 'clinic'
                      : $store.state.AppActiveUser.userType
                  "
                />
              </div>
              <div
                class="mr-2"
                v-if="
                  $store.state.AppActiveUser.userType === 'superAdmin' ||
                  ($store.state.AppActiveUser.userType === 'admin' &&
                    canCreate &&
                    !franchiseId)
                "
              >
                <vs-button
                  @click="onboardNurseHandler"
                  icon-pack="feather"
                  icon="icon-edit"
                  >Onboard Nurse</vs-button
                >
              </div>
              <div
                class="mr-2"
                v-if="
                  check_has_permission('createNurseManagement') && canCreate
                "
              >
                <vs-button
                  @click="createNurseHandler"
                  icon-pack="feather"
                  icon="icon-edit"
                  >Create Nurse</vs-button
                >
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div id="data-list-list-view" class="data-list-container">
        <div class="lg:hidden mt-3">
          <div class="mb-2 flex flex-no-wrap">
            <div class="w-full pr-1">
              <vs-input
                icon="search"
                style="width: 100%"
                placeholder="Search"
                v-model="searchInputString"
              />
            </div>
            <div class="pl-1" style="width: 14.5rem">
              <vs-select
                v-model="dataTableParams.filter"
                placeholder="All Nurses"
                autocomplete
                class="mr-6"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.label"
                  v-for="(item, index) in filterList"
                />
              </vs-select>
            </div>
            <div class="w-24 pl-1">
              <vs-select
                width="100%"
                placeholder="10"
                autocomplete
                v-model="dataTableParams.limit"
              >
                <vs-select-item
                  :key="index"
                  :value="item"
                  :text="item"
                  v-for="(item, index) in limitOptions"
                />
              </vs-select>
            </div>
          </div>
          <ul>
            <li :key="indextr" v-for="(tr, indextr) in users">
              <div
                class="rounded mb-2 p-2 mobile-text"
                style="border: 1px solid #e8e8e8"
              >
                <div class="flex">
                  <h4 class="mr-2 mobile-text">
                    {{
                      users[indextr].firstName
                        ? users[indextr].firstName
                        : "N/A" | capitalize
                    }}
                  </h4>
                  <h4 class="mobile-text">
                    {{
                      users[indextr].lastName
                        ? users[indextr].lastName
                        : "N/A" | capitalize
                    }}
                  </h4>
                </div>
                <div>
                  <p>
                    {{ users[indextr].email }}
                  </p>
                </div>
                <div class="flex">
                  <vx-tooltip
                    v-if="check_has_permission('updateNurseManagement')"
                    text="Edit Nurse Details"
                  >
                    <vs-button
                      style="float: left"
                      type="border"
                      size="small"
                      @click="editDetailHandler(users[indextr]._id)"
                      icon-pack="feather"
                      color="success"
                      class="m-1"
                    >Edit</vs-button>
                  </vx-tooltip>

                  <!-- <vx-tooltip
                  v-if="check_has_permission('viewNurseManagement')"
                  text="View Nurse Details"
                >
                  <vs-button
                    style="float: left"
                    type="border"
                    size="small"
                    @click="viewDetailHandler(users[indextr]._id)"
                    icon-pack="feather"
                    icon="icon-eye"
                    color="success"
                    class="m-1"
                  ></vs-button>
                </vx-tooltip> -->
                </div>
              </div>
            </li>
          </ul>
        </div>
        <vs-table
          class="hidden lg:block"
          ref="table"
          :sst="true"
          :total="totalDocs"
          :data="users"
          @search="search"
          @change-page="handleChangePage"
          @sort="handleSort"
          :max-items="dataTableParams.limit"
          search
          :noDataText="noDataText"
        >
          <div
            slot="header"
            class="flex flex-wrap-reverse flex-grow justify-between"
          >
            <vs-select
              placeholder="10"
              autocomplete
              v-model="dataTableParams.limit"
            >
              <vs-select-item
                :key="index"
                :value="item"
                :text="item"
                v-for="(item, index) in limitOptions"
              />
            </vs-select>

            <div>
              <vs-select
                v-model="dataTableParams.filter"
                placeholder="All Nurses"
                autocomplete
                class="mr-6"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.label"
                  v-for="(item, index) in filterList"
                />
              </vs-select>
            </div>
          </div>

          <template slot="thead">
            <vs-th>Photo</vs-th>
            <vs-th sort-key="firstName">First Name</vs-th>
            <vs-th sort-key="lastName">Last Name</vs-th>
            <vs-th sort-key="email">Email</vs-th>
            <vs-th sort-key="status">Status</vs-th>
            <!-- <vs-th sort-key="hasApprovedByAdmin">Admin Approval</vs-th> -->
            <!-- <vs-th sort-key="createdAt">Created At</vs-th> -->
            <vs-th>Actions</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].profileImage">
                <!-- <vs-avatar
                v-if="data[indextr].profileImage"
                size="70px"
                :src="data[indextr].profileImage"
              />-->
                <div
                  class="medium-img-wrapper"
                  v-if="data[indextr].profileImage"
                  @click="open_in_new_tab(data[indextr].profileImage)"
                >
                  <img
                    :src="data[indextr].profileImage"
                    class="img-fluid"
                    alt
                  />
                </div>
                <p v-else>N/A</p>
              </vs-td>
              <vs-td :data="data[indextr].firstName">{{
                data[indextr].firstName | capitalize
              }}</vs-td>
              <vs-td :data="data[indextr].lastName">{{
                data[indextr].lastName | capitalize
              }}</vs-td>
              <vs-td :data="data[indextr].email">{{
                data[indextr].email
              }}</vs-td>
              <vs-td :data="data[indextr].status">
                <p
                  v-if="data[indextr].status === 'Pending_Approval'"
                  class="text-sm"
                >
                  Pending Approval
                </p>
                <p v-else class="text-sm">{{ data[indextr].status }}</p>
              </vs-td>
              <!-- <vs-td :data="data[indextr].hasApprovedByAdmin">{{
              data[indextr].hasApprovedByAdmin
            }}</vs-td> -->
              <!-- <vs-td :data="data[indextr].createdAt">{{
              data[indextr].createdAt | date_formatter
            }}</vs-td> -->
              <vs-td :data="data[indextr]._id">
                <vx-tooltip
                  v-if="check_has_permission('updateNurseManagement')"
                  text="Edit Nurse Details"
                >
                  <vs-button
                    style="float: left"
                    size="small"
                    type="border"
                    @click="editDetailHandler(data[indextr]._id)"
                    class="m-1"
                  >
                    Edit</vs-button
                  >
                </vx-tooltip>

                <!-- <vx-tooltip
                v-if="check_has_permission('viewNurseManagement')"
                text="View Nurse Details"
              >
                <vs-button
                  style="float: left"
                  type="border"
                  size="small"
                  @click="viewDetailHandler(data[indextr]._id)"
                  icon-pack="feather"
                  icon="icon-eye"
                  color="success"
                  class="m-1"
                ></vs-button>
              </vx-tooltip> -->
                <!--<assign-nurse
                  style="float: left;"
                  class="m-1"
                  v-if="canAssignClinic"
                  :nurseId="data[indextr]._id"
              :associatedIds="data[indextr].associatedClinicIds.map((e) => e.clinicId)" />-->
                <!--<vs-button type="border" v-if="canAssignClinic" size="small" icon-pack="feather" icon="icon-send" color="success" class="m-1"></vs-button>
              <vs-button type="border" size="small" icon-pack="feather" icon="icon-trash" color="danger"></vs-button>-->
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div class="m-2">
          <span class="mr-2">
            {{
              dataTableParams.page * dataTableParams.limit -
              (dataTableParams.limit - 1)
            }}
            -
            {{
              totalDocs - dataTableParams.page * dataTableParams.limit > 0
                ? dataTableParams.page * dataTableParams.limit
                : totalDocs
            }}
            of {{ totalDocs }}
          </span>
          <div class="pagination-div" v-if="serverResponded">
            <paginate
              :page-count="totalPage"
              :click-handler="handleChangePage"
              class="pagination"
              :page-range="9"
              :prevText="'<'"
              :nextText="'>'"
            ></paginate>
          </div>
        </div>
      </div>
    </div>

    <vx-card v-else style="padding-left: 10px">
      <div class="relative">
        <div class="flex flex-no-wrap w-full" vs-w="12">
          <div
            class="mr-2"
            v-if="check_has_permission('exportNurseManagement') && !franchiseId"
          >
            <vs-button
              class="vs-con-loading__container"
              id="button-with-loading"
              @click="exportCsvHandler"
              icon-pack="feather"
              icon="icon-edit"
              :disabled="users.length <= 0"
              >Export as CSV</vs-button
            >
            <a
              id="downloadCustomerCsv"
              ref="downloadCustomerCSVRef"
              :href="customerCSVurl"
            ></a>
          </div>
          <div class="lg:absolute lg:right-0">
            <div class="flex flex-no-wrap">
              <div
                class="mr-2"
                v-if="
                  $store.state.AppActiveUser.userType === 'franchise' ||
                  $store.state.AppActiveUser.userType === 'subFranchise' ||
                  $store.state.AppActiveUser.userType === 'clinic' ||
                  $store.state.AppActiveUser.userType === 'subClinic'
                "
              >
                <invite-user
                  invitationType="Nurse"
                  initiator="management"
                  :clinicCount="clinicCount"
                  :invitedBy="
                    $store.state.AppActiveUser.userType === 'subFranchise'
                      ? 'franchise'
                      : $store.state.AppActiveUser.userType === 'subClinic'
                      ? 'clinic'
                      : $store.state.AppActiveUser.userType
                  "
                />
              </div>
              <div
                class="mr-2"
                v-if="
                  $store.state.AppActiveUser.userType === 'superAdmin' ||
                  ($store.state.AppActiveUser.userType === 'admin' &&
                    canCreate &&
                    !franchiseId)
                "
              >
                <vs-button
                  @click="createUserHandler"
                  icon-pack="feather"
                  icon="icon-edit"
                >
                  Create Nurse
                </vs-button>
              </div>
              <!-- <div
                class="mr-2"
                v-if="
                  $store.state.AppActiveUser.userType === 'superAdmin' ||
                  ($store.state.AppActiveUser.userType === 'admin' &&
                    canCreate &&
                    !franchiseId)
                "
              >
                <vs-button
                  @click="onboardNurseHandler"
                  icon-pack="feather"
                  icon="icon-edit"
                  >Onboard Nurse</vs-button
                >
              </div>
              <div
                class="mr-2"
                v-if="
                  check_has_permission('createNurseManagement') && canCreate
                "
              >
                <vs-button
                  @click="createNurseHandler"
                  icon-pack="feather"
                  icon="icon-edit"
                  >Create Nurse</vs-button
                >
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div id="data-list-list-view" class="data-list-container">
        <div class="lg:hidden mt-3">
          <div class="mb-2 flex flex-no-wrap">
            <div class="w-full pr-1">
              <vs-input
                icon="search"
                style="width: 100%"
                placeholder="Search"
                v-model="searchInputString"
              />
            </div>
            <div class="pl-1" style="width: 14.5rem">
              <vs-select
                v-model="dataTableParams.filter"
                placeholder="All Nurses"
                autocomplete
                class="mr-6"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.label"
                  v-for="(item, index) in filterList"
                />
              </vs-select>
            </div>
            <div class="w-24 pl-1">
              <vs-select
                width="100%"
                placeholder="10"
                autocomplete
                v-model="dataTableParams.limit"
              >
                <vs-select-item
                  :key="index"
                  :value="item"
                  :text="item"
                  v-for="(item, index) in limitOptions"
                />
              </vs-select>
            </div>
          </div>
          <ul>
            <li :key="indextr" v-for="(tr, indextr) in users">
              <div
                class="rounded mb-2 p-2 mobile-text"
                style="border: 1px solid #e8e8e8"
              >
                <div class="flex">
                  <h4 class="mr-2 mobile-text">
                    {{
                      users[indextr].firstName
                        ? users[indextr].firstName
                        : "N/A" | capitalize
                    }}
                  </h4>
                  <h4 class="mobile-text">
                    {{
                      users[indextr].lastName
                        ? users[indextr].lastName
                        : "N/A" | capitalize
                    }}
                  </h4>
                </div>
                <div>
                  <p>
                    {{ users[indextr].email }}
                  </p>
                </div>
                <div class="flex">
                  <vx-tooltip
                    v-if="check_has_permission('updateNurseManagement')"
                    text="Edit Nurse Details"
                  >
                    <vs-button
                      style="float: left"
                      type="border"
                      size="small"
                      @click="editDetailHandler(users[indextr]._id)"
                      icon-pack="feather"
                      color="success"
                      class="m-1"
                    >Edit</vs-button>
                  </vx-tooltip>

                  <!-- <vx-tooltip
                  v-if="check_has_permission('viewNurseManagement')"
                  text="View Nurse Details"
                >
                  <vs-button
                    style="float: left"
                    type="border"
                    size="small"
                    @click="viewDetailHandler(users[indextr]._id)"
                    icon-pack="feather"
                    icon="icon-eye"
                    color="success"
                    class="m-1"
                  ></vs-button>
                </vx-tooltip> -->
                </div>
              </div>
            </li>
          </ul>
        </div>
        <vs-table
          class="hidden lg:block"
          ref="table"
          :sst="true"
          :total="totalDocs"
          :data="users"
          @search="search"
          @change-page="handleChangePage"
          @sort="handleSort"
          :max-items="dataTableParams.limit"
          search
          :noDataText="noDataText"
        >
          <div
            slot="header"
            class="flex flex-wrap-reverse flex-grow justify-between"
          >
            <vs-select
              placeholder="10"
              autocomplete
              v-model="dataTableParams.limit"
            >
              <vs-select-item
                :key="index"
                :value="item"
                :text="item"
                v-for="(item, index) in limitOptions"
              />
            </vs-select>

            <div>
              <vs-select
                v-model="dataTableParams.filter"
                placeholder="All Nurses"
                autocomplete
                class="mr-6"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.label"
                  v-for="(item, index) in filterList"
                />
              </vs-select>
            </div>
          </div>

          <template slot="thead">
            <vs-th>Photo</vs-th>
            <vs-th sort-key="firstName">First Name</vs-th>
            <vs-th sort-key="lastName">Last Name</vs-th>
            <vs-th sort-key="email">Email</vs-th>
            <vs-th sort-key="status">Status</vs-th>
            <!-- <vs-th sort-key="hasApprovedByAdmin">Admin Approval</vs-th> -->
            <!-- <vs-th sort-key="createdAt">Created At</vs-th> -->
            <vs-th>Actions</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].profileImage">
                <!-- <vs-avatar
                v-if="data[indextr].profileImage"
                size="70px"
                :src="data[indextr].profileImage"
              />-->
                <div
                  class="medium-img-wrapper"
                  v-if="data[indextr].profileImage"
                  @click="open_in_new_tab(data[indextr].profileImage)"
                >
                  <img
                    :src="data[indextr].profileImage"
                    class="img-fluid"
                    alt
                  />
                </div>
                <p v-else>N/A</p>
              </vs-td>
              <vs-td :data="data[indextr].firstName">{{
                data[indextr].firstName | capitalize
              }}</vs-td>
              <vs-td :data="data[indextr].lastName">{{
                data[indextr].lastName | capitalize
              }}</vs-td>
              <vs-td :data="data[indextr].email">{{
                data[indextr].email
              }}</vs-td>
              <vs-td :data="data[indextr].status">
                <p
                  v-if="data[indextr].status === 'Pending_Approval'"
                  class="text-sm"
                >
                  Pending Approval
                </p>
                <p v-else class="text-sm">{{ data[indextr].status }}</p>
              </vs-td>
              <!-- <vs-td :data="data[indextr].hasApprovedByAdmin">{{
              data[indextr].hasApprovedByAdmin
            }}</vs-td> -->
              <!-- <vs-td :data="data[indextr].createdAt">{{
              data[indextr].createdAt | date_formatter
            }}</vs-td> -->
              <vs-td :data="data[indextr]._id">
                  <vs-button
                    style="float: left"
                    size="small"
                    type='border'
                    @click="editDetailHandler(data[indextr]._id)"
                    class="m-1"
                  >
                    Edit</vs-button
                  >

                <!-- <vx-tooltip
                v-if="check_has_permission('viewNurseManagement')"
                text="View Nurse Details"
              >
                <vs-button
                  style="float: left"
                  type="border"
                  size="small"
                  @click="viewDetailHandler(data[indextr]._id)"
                  icon-pack="feather"
                  icon="icon-eye"
                  color="success"
                  class="m-1"
                ></vs-button>
              </vx-tooltip> -->
                <!--<assign-nurse
                  style="float: left;"
                  class="m-1"
                  v-if="canAssignClinic"
                  :nurseId="data[indextr]._id"
              :associatedIds="data[indextr].associatedClinicIds.map((e) => e.clinicId)" />-->
                <!--<vs-button type="border" v-if="canAssignClinic" size="small" icon-pack="feather" icon="icon-send" color="success" class="m-1"></vs-button>
              <vs-button type="border" size="small" icon-pack="feather" icon="icon-trash" color="danger"></vs-button>-->
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div class="m-2">
          <span class="mr-2">
            {{
              dataTableParams.page * dataTableParams.limit -
              (dataTableParams.limit - 1)
            }}
            -
            {{
              totalDocs - dataTableParams.page * dataTableParams.limit > 0
                ? dataTableParams.page * dataTableParams.limit
                : totalDocs
            }}
            of {{ totalDocs }}
          </span>
          <div class="pagination-div" v-if="serverResponded">
            <paginate
              :page-count="totalPage"
              :click-handler="handleChangePage"
              class="pagination"
              :page-range="9"
              :prevText="'<'"
              :nextText="'>'"
            ></paginate>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";
import assignNurse from "../../components/others/assignNurse";
import inviteUser from "../../components/others/inviteUser";

export default {
  name: "nurse-table",
  components: {
    "v-select": vSelect,
    "assign-nurse": assignNurse,
    inviteUser,
  },
  props: {
    inTabEdit: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    viewRoute: {
      type: String,
      default: "FranchiseNurseDetails",
    },
    viewDetailRoute: {
      type: String,
      default: "SuperAdminNurseDetailView",
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    createRoute: {
      type: String,
      default: "FranchiseNurseCreate",
    },
    canAssignClinic: {
      type: Boolean,
      default: false,
    },
    franchiseId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      noDataText: "Loading..",
      clinicCount: 0,
      customerCSVurl: false,
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      searchInputString: null,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
        filter: "All",
        activeClinicId: "",
        franchiseId: "",
      },
      users: [],
      serverResponded: false,
      limitOptions: [5, 10, 25, 50, 100],
      filterList: [
        { value: "All", label: "All Nurses" },
        { value: "Approved", label: "Approved" },
        { value: "Unapproved", label: "Unapproved" },
      ],
      awaitingSearch: null,
    };
  },
  methods: {
    ...mapActions("nurse", ["fetchNurses", "exportCsv"]),
    search(searching) {
      this.searchInputString = searching;
    },
    moment(date) {
      return moment(date);
    },
    getNursesList() {
      let self = this;
      this.$vs.loading();
      this.fetchNurses(self.dataTableParams).then((res) => {
        this.$vs.loading.close();
        self.noDataText = "No Nurse Available";
        self.serverResponded = true;
        self.users = res.data.data.docs;
        self.totalDocs =
          res.data.data.pagination.total >= 0 ? res.data.data.pagination.total : res.data.data.pagination[0].total;
        self.page =
          res.data.data.pagination.page || res.data.data.pagination[0].page;
        self.currentPage =
          res.data.data.pagination.page || res.data.data.pagination[0].page;
        if (res.data.data && res.data.data.clinicCount) {
          if (
            this.$store.state.AppActiveUser.userType === "franchise" ||
            this.$store.state.AppActiveUser.userType === "subFranchise"
          )
            self.clinicCount = res.data.data.clinicCount;
          if (
            this.$store.state.AppActiveUser.userType === "clinic" ||
            this.$store.state.AppActiveUser.userType === "subClinic"
          )
            self.clinicCount = 1;
        }
      });
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getNursesList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getNursesList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getNursesList();
    },
    editDetailHandler(id) {
      this.$router.push({ name: this.viewRoute, params: { nurseId: id } });
    },
    viewDetailHandler(id) {
      this.$router.push({
        name: this.viewDetailRoute,
        params: { nurseId: id },
      });
    },
    createNurseHandler() {
      this.$router.push({ name: this.createRoute });
    },
    onboardNurseHandler() {
      this.$router.push({ name: "SuperAdminNurseOnboard" });
    },
    createUserHandler() {
      this.$store.commit("nurse/CREATE_USER_TYPE", "Nurse");
      this.$router.push({ 
        name: "SuperAdminCreateUser", 
        params: { activeClinicId: this.dataTableParams.activeClinicId } 
      });
    },
    exportCsvHandler() {
      // let _instance = this;

      this.$vs.loading({
        background: "primary",
        color: "#fff",
        container: `#button-with-loading`,
        scale: 0.45,
      });

      // let csvLinkInstance = _instance.$refs.downloadCustomerCSVRef;

      //csvLinkInstance.click();
      this.exportCsv()
        .then(async (res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "freshclinic-nurses.csv"); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.$vs.loading.close("#button-with-loading > .con-vs-loading");
        })
        .catch((err) => {
          this.$vs.loading.close("#button-with-loading > .con-vs-loading");
          console.log(err);
        });
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    searchInputString: function (newVal, oldVal) {
      this.handleSearch(newVal);
    },
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getNursesList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getNursesList();
      }
    },
    "dataTableParams.filter": function (newfilterVal, oldFilterVal) {
      if (newfilterVal !== oldFilterVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.filter = newfilterVal;
        this.getNursesList();
      }
    },
    "$store.state.AppActiveClinicId": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.activeClinicId = newVal;
        this.getNursesList();
      }
    },
  },
  async created() {
    this.dataTableParams.activeClinicId = (await this.$route.params.clinicId)
      ? this.$route.params.clinicId
      : this.$store.state.AppActiveClinicId;
    if (this.franchiseId && this.franchiseId !== "") {
      this.dataTableParams.franchiseId = this.franchiseId;
    }
    this.getNursesList();
  },
};
</script>
<style scoped>
.mobile-text {
  color: #164d3d;
}
</style>
